import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})
export class NavService {
  public screenWidth: any;
  public collapseSidebar: boolean = false;
  public fullScreen = false;

  constructor() {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = true;
    }
  }

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.screenWidth = window.innerWidth;
  }

  MENUITEMS: Menu[] = [
    {
      title: 'appointment.management',
      icon: 'calendar',
      type: 'link',
      path: '/lich-hen'
    },
    {
      title: 'dm.management',
      icon: 'home',
      badgeType: 'primary',
      type: 'link',
      path: '/kim-cuong'
    },
    {
      title: 'djm.management',
      icon: 'box',
      type: 'link',
      path: '/trang-suc-kim-cuong'
    },
    {
      title: 'wishlist.management',
      icon: 'book',
      type: 'link',
      path: '/wishlist'
    },
    {
      title: 'blogs.management',
      icon: 'book-open',
      type: 'link',
      path: '/blogs'
    },
    {
      title: 'user.management',
      icon: 'user',
      type: 'link',
      path: '/users'
    },
    {
      title: 'landing.configuration',
      icon: 'layout',
      type: 'link',
      path: '/landing-page'
    },
    {
      title: 'footer.management',
      icon: 'settings',
      type: 'link',
      path: '/footer-management'
    },
    {
      title: 'currency.management',
      icon: 'dollar-sign',
      type: 'link',
      path: '/currency-management'
    }
    // {
    //   title: 'Category',
    //   icon: 'bar-chart',
    //   type: 'sub',
    //   active: false,
    //   children: [
    //     { path: '/chart/google', title: 'Google Chart', type: 'link' },
    //     { path: '/chart/chartjs', title: 'Chartjs', type: 'link' },
    //     { path: '/chart/chartist', title: 'Chartist', type: 'link' },
    //     { path: '/chart/ngx-chart', title: 'Ngx-Chart', type: 'link' }
    //   ]
    // }
  ];
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
