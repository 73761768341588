import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { LoaderComponent } from "./components/loader/loader.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { ContentLayoutComponent } from "./components/layout/content-layout/content-layout.component";
import { FullLayoutComponent } from "./components/layout/full-layout/full-layout.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { BookmarkComponent } from "./components/bookmark/bookmark.component";
import { TranslateModule } from "@ngx-translate/core";
import { CustomizerComponent } from "./components/customizer/customizer.component";
import { NgbActiveModal, NgbDatepickerModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { GalleryModule } from "@ks89/angular-modal-gallery";

// services
import { NavService } from "./services/nav.service";
import { CustomizerService } from "./services/customizer.service";
// Directives
import { ToggleFullscreenDirective } from "./directives/fullscreen.directive";
import { NgbdSortableHeader } from "./directives/NgbdSortableHeader";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { GalleryComponent } from "./components/gallery/gallery.component";
import { HttpInterceptorService } from "./services/http-interceptor.service";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    GalleryComponent,
    SidebarComponent,
    BookmarkComponent,
    ContentLayoutComponent,
    FullLayoutComponent,
    FeatherIconsComponent,
    ToggleFullscreenDirective,
    NgbdSortableHeader,
    BreadcrumbComponent,
    CustomizerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    NgbModule,
    GalleryModule,
    HttpClientModule,
    LazyLoadImageModule,
    ReactiveFormsModule,
    FormsModule,
    NgbDatepickerModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    NgbModule,
    GalleryModule,
    HttpClientModule,
    LazyLoadImageModule,
    ReactiveFormsModule,
    FormsModule,
    NgbDatepickerModule,
    LoaderComponent,
    FeatherIconsComponent,
    NgbdSortableHeader,
    GalleryComponent,
    
  ],
  providers: [
    NavService,
    CustomizerService,
    NgbActiveModal,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
  ]
})
export class SharedModule {}
