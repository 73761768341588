import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;

  public errorMessage: any;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private http: HttpClient,
    private ngZone: NgZone
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
    const user = localStorage.getItem('user');
    const accessToken = localStorage.getItem('accessToken');
    if (user && accessToken) {
      this.ngZone.runOutsideAngular(() => {
        this.router.navigate(['/lich-hen']);
      });
    }
  }

  login() {
    if (this.loginForm.valid) {
      const email = this.loginForm.value['email'];
      const password = this.loginForm.value['password'];

      this.http
        .post(environment.apiUrl + '/users/login', { email, password })
        .subscribe({
          next: (response: any) => {
            const user = response.data.user;
            const accessToken = response.data.accessToken;
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('accessToken', accessToken);
            this.ngZone.runOutsideAngular(() => {
              this.router.navigate(['/lich-hen']);
            });
          },
          error: (e: any) => {
            this.errorMessage = e.message;
          }
        });
    }
  }

  loginFacebook() {}
  loginTwitter() {}
  loginGoogle() {}
}
