import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { LoginComponent } from "./auth/login/login.component";
import { ContentLayoutComponent } from "./shared/components/layout/content-layout/content-layout.component";
import { FullLayoutComponent } from "./shared/components/layout/full-layout/full-layout.component";
import { content } from "./shared/routes/content-routes";
import { full } from "./shared/routes/full.routes";
import { AdminGuard, LoginGuard } from "./shared/guard/admin.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "kim-cuong",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "",
    component: ContentLayoutComponent,
    canActivate: [LoginGuard],
    children: content
  },
  {
    path: "",
    component: FullLayoutComponent,
    canActivate: [LoginGuard],
    children: full
  },
  {
    path: "**",
    redirectTo: "login"
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
