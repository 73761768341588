import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Input } from "@angular/core";
import {
  Gallery,
  GalleryItem,
  ImageItem,
  ThumbnailsPosition,
  ImageSize
} from "ng-gallery";
import { Lightbox } from "ng-gallery/lightbox";

@Component({
  selector: "app-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class GalleryComponent implements OnInit {
  items: GalleryItem[];

  @Input()
  imageData: any[] = [];

  constructor(public gallery: Gallery, public lightbox: Lightbox) {}

  ngOnInit() {
    // Creat gallery items
    //this.items = this.imageData.map(
    //  (item) => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl })
    //);

    /** Lightbox Example */

    // Get a lightbox gallery ref
    const lightboxRef = this.gallery.ref("lightbox");

    // Add custom gallery config to the lightbox (optional)
    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top
    });

    // Load items into the lightbox gallery ref
    lightboxRef.load(this.items);
  }
}
