import {
  Component,
  OnInit,
  AfterViewInit,
  HostListener,
  ChangeDetectorRef
} from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce, zoomOut, zoomIn, fadeIn, bounceIn } from 'ng-animate';
import { NavService } from '../../../services/nav.service';
import { CustomizerService } from '../../../services/customizer.service';
import * as feather from 'feather-icons';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
  animations: [
    trigger('animateRoute', [
      transition(
        '* => *',
        useAnimation(fadeIn, {
          // Set the duration to 5seconds and delay to 2 seconds
          //params: { timing: 3}
        })
      )
    ])
  ]
})
export class ContentLayoutComponent implements OnInit, AfterViewInit {
  public right_side_bar: boolean;
  public outletState: any;
  constructor(
    public navServices: NavService,
    public customizer: CustomizerService,
    private cdref: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
    this.cdref.detectChanges();
  }

  @HostListener('document:click', ['$event'])
  clickedOutside(event: any) {
    // click outside Area perform following action
    document.getElementById('outer-container')!.onclick = function (e) {
      e.stopPropagation();
      if (e.target != document.getElementById('search-outer')) {
        document.getElementsByTagName('body')[0].classList.remove('offcanvas');
      }
      if (e.target != document.getElementById('outer-container')) {
        //document
        //  .getElementById('canvas-bookmark')!
        //  .classList.remove('offcanvas-bookmark');
      }
      if (e.target != document.getElementById('inner-customizer')) {
        //document
        //  .getElementsByClassName('customizer-links')[0]
        //  .classList.remove('open');
        //document
        //  .getElementsByClassName('customizer-contain')[0]
        //  .classList.remove('open');
      }
    };
  }

  public getRouterOutletState(outlet: any) {
    const newState = outlet.isActivated ? outlet.activatedRoute : '';
    if (newState !== this.outletState) {
      this.outletState = newState;
    }
    return this.outletState;
  }

  public rightSidebar($event: any) {
    this.right_side_bar = $event;
  }

  ngOnInit() {}
}
