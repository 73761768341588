import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class LoginGuard {
  constructor(public router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let user = localStorage.getItem("user");
    if (!user) {
      this.router.navigate(["login"]);
      return false;
    }
    return true;
  }
}

@Injectable({
  providedIn: "root"
})
export class AdminGuard {
  constructor(public router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let user = localStorage.getItem("user");
    if (!user) {
      this.router.navigate(["login"]);
      return false;
    }

    if((JSON.parse(user) as any).role !== "admin") {
      this.router.navigate(["/"]);
      return false;
    }
    return true;
  }
}