import { Routes } from "@angular/router";
import { AdminGuard } from "../guard/admin.guard";

export const content: Routes = [
  {
    path: "kim-cuong",
    loadChildren: () =>
      import(
        "../../modules/diamond-management/diamond-management.module"
      ).then((m) => m.DiamondManagementModule),
  },
  {
    path: "trang-suc-kim-cuong",
    loadChildren: () =>
      import(
        "../../modules/diamond-jewelry-management/diamond-jewelry-management.module"
      ).then((m) => m.DiamondJewelryModule),
  },
  {
    path: "users",
    loadChildren: () =>
      import("../../modules/users/users.module").then((m) => m.TablesModule),
    canActivate: [AdminGuard],
  },
  {
    path: "wishlist",
    loadChildren: () =>
      import("../../modules/wishlist/wishlist.module").then((m) => m.WishlistModule),
  },
  {
    path: "lich-hen",
    loadChildren: () =>
      import("../../modules/appointment/appointment.module").then((m) => m.AppointmentModule),
  },
  {
    path: "landing-page",
    loadChildren: () =>
      import("../../modules/page-config/page-config.module").then((m) => m.PageConfigModule),
  },
  {
    path: "blogs",
    loadChildren: () =>
      import("../../modules/blogs/blogs.module").then((m) => m.BlogsModule),
  },
  {
    path: "footer-management",
    loadChildren: () =>
      import("../../modules/footer-management/footer-management.module").then((m) => m.FooterManagementModule),
  },
  {
    path: "currency-management",
    loadChildren: () =>
      import("../../modules/currency-management/currency-management.module").then((m) => m.CurrencyManagementModule),
  },
];

